@import "variables";
@import "functions";
@import "mixins";

@font-face {
  font-family: $font-icons-family-name;
  src: url('#{$icomoon-font-path}#{$font-icons-family-name}.woff') format('woff'),
    url('#{$icomoon-font-path}#{$font-icons-family-name}.ttf') format('truetype'),
    url('#{$icomoon-font-path}#{$font-icons-family-name}.svg##{$font-icons-family-name}') format('svg');
  font-weight: normal;
  font-style: normal;
}

.tts-font-icons {
  display: block;
}

[class^="icon-"]:not(.icon-with-text), [class*=" icon-"]:not(.icon-with-text) {
  @include font-icon-basic();
}

@import "icons";
@import "custom-icons";