@import "variables";
@import "functions";

.icon-exclamation-circle {
  &:before {
    content: unicode(icon-info-circle);
    transform: rotate(180deg);
    display: inline-block;
  }
}

.icon-map-marker {
  position: relative;

  &:before {
      content: unicode($icon-map-marker-filling);
      color: white;
      position: absolute;
      left: 0;
  }

  &:after {
      content: unicode($icon-map-marker-unfilled);
  }
}

.icon-map-marker-top {
  position: relative;

  &:before {
      content: unicode($icon-map-marker-filling);
      color: #46b93a;
      position: absolute;
      left: 0;
  }

  &:after {
      content: unicode($icon-map-marker-unfilled);
  }
}