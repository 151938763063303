@import "variables";
@import "functions";

.icon-basket {
  &:before {
    content: $icon-basket;
  }
}
.icon-facebook-thumb {
  &:before {
    content: $icon-facebook-thumb;
  }
}
.icon-facebook-share {
  &:before {
    content: $icon-facebook-share;
  }
}
.icon-comments-social-feed {
  &:before {
    content: $icon-comments-social-feed;
  }
}
.icon-facebook-social-feed {
  &:before {
    content: $icon-facebook-social-feed;
  }
}
.icon-instagram-social-feed {
  &:before {
    content: $icon-instagram-social-feed;
  }
}
.icon-likes-social-feed {
  &:before {
    content: $icon-likes-social-feed;
  }
}
.icon-views-social-feed {
  &:before {
    content: $icon-views-social-feed;
  }
}
.icon-youtube-social-feed {
  &:before {
    content: $icon-youtube-social-feed;
  }
}
.icon-fog {
  &:before {
    content: $icon-fog;
  }
}
.icon-star-half {
  &:before {
    content: $icon-star-half;
  }
}
.icon-star-half-o {
  &:before {
    content: $icon-star-half-o;
  }
}
.icon-star-half-empty {
  &:before {
    content: $icon-star-half-empty;
  }
}
.icon-star-half-full {
  &:before {
    content: $icon-star-half-full;
  }
}
.icon-star {
  &:before {
    content: $icon-star;
  }
}
.icon-star-o {
  &:before {
    content: $icon-star-o;
  }
}
.icon-map-marker-unfilled {
  &:before {
    content: $icon-map-marker-unfilled;
  }
}
.icon-map-marker-filling {
  &:before {
    content: $icon-map-marker-filling;
  }
}
.icon-press {
  &:before {
    content: $icon-press;
  }
}
.icon-link {
  &:before {
    content: $icon-link;
  }
}
.icon-quicklinks {
  &:before {
    content: $icon-quicklinks;
  }
}
.icon-travel-safety {
  &:before {
    content: $icon-travel-safety;
  }
}
.icon-repair-order {
  &:before {
    content: $icon-repair-order;
  }
}
.icon-crm {
  &:before {
    content: $icon-crm;
  }
}
.icon-festool-connect {
  &:before {
    content: $icon-festool-connect;
  }
}
.icon-shop {
  &:before {
    content: $icon-shop;
  }
}
.icon-shopping-chart {
  &:before {
    content: $icon-shopping-chart;
  }
}
.icon-sitemap {
  &:before {
    content: $icon-sitemap;
  }
}
.icon-canteen {
  &:before {
    content: $icon-canteen;
  }
}
.icon-cafeteria {
  &:before {
    content: $icon-cafeteria;
  }
}
.icon-food {
  &:before {
    content: $icon-food;
  }
}
.icon-cube {
  &:before {
    content: $icon-cube;
  }
}
.icon-pim {
  &:before {
    content: $icon-pim;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;
  }
}
.icon-emergency-call {
  &:before {
    content: $icon-emergency-call;
  }
}
.icon-emergency-number {
  &:before {
    content: $icon-emergency-number;
  }
}
.icon-share {
  &:before {
    content: $icon-share;
  }
}
.icon-social {
  &:before {
    content: $icon-social;
  }
}
.icon-share-square {
  &:before {
    content: $icon-share-square;
  }
}
.icon-google-plus {
  &:before {
    content: $icon-google-plus;
  }
}
.icon-google-plus-square {
  &:before {
    content: $icon-google-plus-square;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}
.icon-twitter-square {
  &:before {
    content: $icon-twitter-square;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon-instagram-square {
  &:before {
    content: $icon-instagram-square;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-facebook-square {
  &:before {
    content: $icon-facebook-square;
  }
}
.icon-facebook-messenger {
  &:before {
    content: $icon-facebook-messenger;
  }
}
.icon-pinterest {
  &:before {
    content: $icon-pinterest;
  }
}
.icon-pinterest-square {
  &:before {
    content: $icon-pinterest-square;
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube;
  }
}
.icon-youtube-square {
  &:before {
    content: $icon-youtube-square;
  }
}
.icon-wechat {
  &:before {
    content: $icon-wechat;
  }
}
.icon-weibo {
  &:before {
    content: $icon-weibo;
  }
}
.icon-tencent {
  &:before {
    content: $icon-tencent;
  }
}
.icon-google {
  &:before {
    content: $icon-google;
  }
}
.icon-windows {
  &:before {
    content: $icon-windows;
  }
}
.icon-microsoft {
  &:before {
    content: $icon-microsoft;
  }
}
.icon-rss {
  &:before {
    content: $icon-rss;
  }
}
.icon-whatsapp {
  &:before {
    content: $icon-whatsapp;
  }
}
.icon-mail-share {
  &:before {
    content: $icon-mail-share;
  }
}
.icon-web-mail {
  &:before {
    content: $icon-web-mail;
  }
}
.icon-sun-o {
  &:before {
    content: $icon-sun-o;
  }
}
.icon-weather {
  &:before {
    content: $icon-weather;
  }
}
.icon-alert {
  &:before {
    content: $icon-alert;
  }
}
.icon-bell-o {
  &:before {
    content: $icon-bell-o;
  }
}
.icon-meeting-room {
  &:before {
    content: $icon-meeting-room;
  }
}
.icon-desk {
  &:before {
    content: $icon-desk;
  }
}
.icon-business-objects {
  &:before {
    content: $icon-business-objects;
  }
}
.icon-monitoring {
  &:before {
    content: $icon-monitoring;
  }
}
.icon-chat {
  &:before {
    content: $icon-chat;
  }
}
.icon-comments-o {
  &:before {
    content: $icon-comments-o;
  }
}
.icon-download {
  &:before {
    content: $icon-download;
  }
}
.icon-ekat {
  &:before {
    content: $icon-ekat;
  }
}
.icon-spare-parts-catalog {
  &:before {
    content: $icon-spare-parts-catalog;
  }
}
.icon-database {
  &:before {
    content: $icon-database;
  }
}
.icon-ekdb {
  &:before {
    content: $icon-ekdb;
  }
}
.icon-fcc {
  &:before {
    content: $icon-fcc;
  }
}
.icon-operating-data-collection {
  &:before {
    content: $icon-operating-data-collection;
  }
}
.icon-folder-open-a {
  &:before {
    content: $icon-folder-open-a;
  }
}
.icon-file-o {
  &:before {
    content: $icon-file-o;
  }
}
.icon-file-code-o {
  &:before {
    content: $icon-file-code-o;
  }
}
.icon-file-text-o {
  &:before {
    content: $icon-file-text-o;
  }
}
.icon-file-log-o {
  &:before {
    content: $icon-file-log-o;
  }
}
.icon-file-zip-o {
  &:before {
    content: $icon-file-zip-o;
  }
}
.icon-file-archive-o {
  &:before {
    content: $icon-file-archive-o;
  }
}
.icon-file-audio {
  &:before {
    content: $icon-file-audio;
  }
}
.icon-file-sound {
  &:before {
    content: $icon-file-sound;
  }
}
.icon-file-excel-o {
  &:before {
    content: $icon-file-excel-o;
  }
}
.icon-file-image-o {
  &:before {
    content: $icon-file-image-o;
  }
}
.icon-file-photo-o {
  &:before {
    content: $icon-file-photo-o;
  }
}
.icon-file-picture-o {
  &:before {
    content: $icon-file-picture-o;
  }
}
.icon-mam {
  &:before {
    content: $icon-mam;
  }
}
.icon-file-infopath-o {
  &:before {
    content: $icon-file-infopath-o;
  }
}
.icon-file-onenote-o {
  &:before {
    content: $icon-file-onenote-o;
  }
}
.icon-file-pdf-o {
  &:before {
    content: $icon-file-pdf-o;
  }
}
.icon-file-powerpoint-o {
  &:before {
    content: $icon-file-powerpoint-o;
  }
}
.icon-file-video-o {
  &:before {
    content: $icon-file-video-o;
  }
}
.icon-file-movie-o {
  &:before {
    content: $icon-file-movie-o;
  }
}
.icon-file-word-o {
  &:before {
    content: $icon-file-word-o;
  }
}
.icon-user {
  &:before {
    content: $icon-user;
  }
}
.icon-customer-account {
  &:before {
    content: $icon-customer-account;
  }
}
.icon-users {
  &:before {
    content: $icon-users;
  }
}
.icon-forum {
  &:before {
    content: $icon-forum;
  }
}
.icon-cog {
  &:before {
    content: $icon-cog;
  }
}
.icon-wheel {
  &:before {
    content: $icon-wheel;
  }
}
.icon-settings {
  &:before {
    content: $icon-settings;
  }
}
.icon-config {
  &:before {
    content: $icon-config;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
  }
}
.icon-bars {
  &:before {
    content: $icon-bars;
  }
}
.icon-hamburger {
  &:before {
    content: $icon-hamburger;
  }
}
.icon-filter {
  &:before {
    content: $icon-filter;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-lupe {
  &:before {
    content: $icon-lupe;
  }
}
.icon-location {
  &:before {
    content: $icon-location;
  }
}
.icon-gps {
  &:before {
    content: $icon-gps;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-cross {
  &:before {
    content: $icon-cross;
  }
}
.icon-x {
  &:before {
    content: $icon-x;
  }
}
.icon-times {
  &:before {
    content: $icon-times;
  }
}
.icon-tick {
  &:before {
    content: $icon-tick;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-angle-double-up {
  &:before {
    content: $icon-angle-double-up;
  }
}
.icon-angle-double-right {
  &:before {
    content: $icon-angle-double-right;
  }
}
.icon-angle-double-down {
  &:before {
    content: $icon-angle-double-down;
  }
}
.icon-angle-double-left {
  &:before {
    content: $icon-angle-double-left;
  }
}
.icon-angle-up {
  &:before {
    content: $icon-angle-up;
  }
}
.icon-angle-right {
  &:before {
    content: $icon-angle-right;
  }
}
.icon-angle-down {
  &:before {
    content: $icon-angle-down;
  }
}
.icon-angle-left {
  &:before {
    content: $icon-angle-left;
  }
}
.icon-back-top {
  &:before {
    content: $icon-back-top;
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-minus {
  &:before {
    content: $icon-minus;
  }
}
.icon-play {
  &:before {
    content: $icon-play;
  }
}
.icon-playbutton {
  &:before {
    content: $icon-playbutton;
  }
}
.icon-youtube-play {
  &:before {
    content: $icon-youtube-play;
  }
}
.icon-play-circle {
  &:before {
    content: $icon-play-circle;
  }
}
.icon-play-circle-o {
  &:before {
    content: $icon-play-circle-o;
  }
}
.icon-validation-check {
  &:before {
    content: $icon-validation-check;
  }
}
.icon-refresh {
  &:before {
    content: $icon-refresh;
  }
}
.icon-save {
  &:before {
    content: $icon-save;
  }
}
.icon-floppy-o {
  &:before {
    content: $icon-floppy-o;
  }
}
.icon-print {
  &:before {
    content: $icon-print;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
  }
}
.icon-house {
  &:before {
    content: $icon-house;
  }
}
.icon-envelope-o {
  &:before {
    content: $icon-envelope-o;
  }
}
.icon-e-mail {
  &:before {
    content: $icon-e-mail;
  }
}
.icon-mail {
  &:before {
    content: $icon-mail;
  }
}
.icon-fax {
  &:before {
    content: $icon-fax;
  }
}
.icon-event {
  &:before {
    content: $icon-event;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}
.icon-date {
  &:before {
    content: $icon-date;
  }
}
.icon-list {
  &:before {
    content: $icon-list;
  }
}
.icon-caret-right {
  &:before {
    content: $icon-caret-right;
  }
}
.icon-triangle-right {
  &:before {
    content: $icon-triangle-right;
  }
}
.icon-info-circle {
  &:before {
    content: $icon-info-circle;
  }
}
.icon-lightbulb-o {
  &:before {
    content: $icon-lightbulb-o;
  }
}
.icon-ideas-tool {
  &:before {
    content: $icon-ideas-tool;
  }
}
.icon-estate-ticket-tool {
  &:before {
    content: $icon-estate-ticket-tool;
  }
}
.icon-book {
  &:before {
    content: $icon-book;
  }
}
.icon-documentation {
  &:before {
    content: $icon-documentation;
  }
}
.icon-it-documentation {
  &:before {
    content: $icon-it-documentation;
  }
}
.icon-it-servicedesk {
  &:before {
    content: $icon-it-servicedesk;
  }
}
.icon-money {
  &:before {
    content: $icon-money;
  }
}
.icon-it-billing {
  &:before {
    content: $icon-it-billing;
  }
}
.icon-briefcase {
  &:before {
    content: $icon-briefcase;
  }
}
.icon-bag {
  &:before {
    content: $icon-bag;
  }
}
.icon-jobs {
  &:before {
    content: $icon-jobs;
  }
}
.icon-identity-card {
  &:before {
    content: $icon-identity-card;
  }
}
.icon-customer-data {
  &:before {
    content: $icon-customer-data;
  }
}
.icon-sign-in {
  &:before {
    content: $icon-sign-in;
  }
}
.icon-log-in {
  &:before {
    content: $icon-log-in;
  }
}
.icon-login {
  &:before {
    content: $icon-login;
  }
}
.icon-sign-out {
  &:before {
    content: $icon-sign-out;
  }
}
.icon-log-out {
  &:before {
    content: $icon-log-out;
  }
}
.icon-logout {
  &:before {
    content: $icon-logout;
  }
}
.icon-media-asset-mail {
  &:before {
    content: $icon-media-asset-mail;
  }
}
.icon-media-download {
  &:before {
    content: $icon-media-download;
  }
}
.icon-media-generation {
  &:before {
    content: $icon-media-generation;
  }
}
.icon-mobile {
  &:before {
    content: $icon-mobile;
  }
}
.icon-mobile-phone {
  &:before {
    content: $icon-mobile-phone;
  }
}
.icon-newspaper-o {
  &:before {
    content: $icon-newspaper-o;
  }
}
.icon-news {
  &:before {
    content: $icon-news;
  }
}
.icon-user-search {
  &:before {
    content: $icon-user-search;
  }
}
.icon-people-search {
  &:before {
    content: $icon-people-search;
  }
}
.icon-call {
  &:before {
    content: $icon-call;
  }
}
.icon-phone {
  &:before {
    content: $icon-phone;
  }
}
.icon-cashback {
  &:before {
    content: $icon-cashback;
  }
}
.icon-festool {
  &:before {
    content: $icon-festool;
    color: #46b93a;
  }
}
