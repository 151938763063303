@mixin font-icon-basic($font-size: 1.5em) {
    // Use !important to prevent issues with browser extensions that change fonts
    font-family: $font-icons-family, sans-serif !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: $font-size;
    vertical-align: baseline;

    // Better Font Rendering
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin font-icon($icon, $font-size: 1.5em) {
    @include font-icon-basic($font-size);
    content: unicode($icon);
}

// Usage:
// .festool-logo { @include font-icon($icon-festool); }