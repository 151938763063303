// ######## DO NOT OVERWRITE THIS! ########

$packageVersion: "" !default;
$font-icons-family-name: "TTS-Font-Icons";
$font-icons-family: "#{$font-icons-family-name}";

// ########################################

$icomoon-fallback-path: "../fonts/" !default;
$icomoon-font-path: $icomoon-fallback-path !default;

$icon-basket: "\1f5d1";
$icon-facebook-thumb: "\e914";
$icon-facebook-share: "\e915";
$icon-comments-social-feed: "\e90d";
$icon-facebook-social-feed: "\e90e";
$icon-instagram-social-feed: "\e910";
$icon-likes-social-feed: "\e911";
$icon-views-social-feed: "\e912";
$icon-youtube-social-feed: "\e913";
$icon-fog: "\e908";
$icon-star-half: "\f089";
$icon-star-half-o: "\f123";
$icon-star-half-empty: "\f123";
$icon-star-half-full: "\f123";
$icon-star: "\2605";
$icon-star-o: "\2606";
$icon-map-marker-unfilled: "\1f4cd";
$icon-map-marker-filling: "\220e";
$icon-press: "\e907";
$icon-link: "\1f517";
$icon-quicklinks: "\1f517";
$icon-travel-safety: "\1f686";
$icon-repair-order: "\1f527";
$icon-crm: "\e90f";
$icon-festool-connect: "\e90f";
$icon-shop: "\1f6d2";
$icon-shopping-chart: "\1f6d2";
$icon-sitemap: "\26fc";
$icon-canteen: "\1f37d";
$icon-cafeteria: "\1f37d";
$icon-food: "\1f37d";
$icon-cube: "\1f54b";
$icon-pim: "\1f54b";
$icon-edit: "\1f4dd";
$icon-emergency-call: "\1f198";
$icon-emergency-number: "\1f198";
$icon-share: "\e906";
$icon-social: "\e906";
$icon-share-square: "\f14d";
$icon-google-plus: "\271a";
$icon-google-plus-square: "\271a";
$icon-twitter: "\1f426";
$icon-twitter-square: "\1f426";
$icon-instagram: "\29c7";
$icon-instagram-square: "\29c7";
$icon-facebook: "\46";
$icon-facebook-square: "\46";
$icon-facebook-messenger: "\e909";
$icon-pinterest: "\50";
$icon-pinterest-square: "\50";
$icon-youtube: "\1f3ac";
$icon-youtube-square: "\1f3ac";
$icon-wechat: "\e900";
$icon-weibo: "\e904";
$icon-tencent: "\e905";
$icon-google: "\47";
$icon-windows: "\229e";
$icon-microsoft: "\229e";
$icon-rss: "\e90a";
$icon-whatsapp: "\e90c";
$icon-mail-share: "\e90b";
$icon-web-mail: "\1f4e7";
$icon-sun-o: "\f185";
$icon-weather: "\f185";
$icon-alert: "\1f514";
$icon-bell-o: "\1f514";
$icon-meeting-room: "\e921";
$icon-desk: "\e921";
$icon-business-objects: "\e922";
$icon-monitoring: "\e922";
$icon-chat: "\f0e6";
$icon-comments-o: "\f0e6";
$icon-download: "\e902";
$icon-ekat: "\1f529";
$icon-spare-parts-catalog: "\1f529";
$icon-database: "\f1c0";
$icon-ekdb: "\f1c0";
$icon-fcc: "\f1c0";
$icon-operating-data-collection: "\f115";
$icon-folder-open-a: "\f115";
$icon-file-o: "\1f4c4";
$icon-file-code-o: "\1f4c4";
$icon-file-text-o: "\1f4c4";
$icon-file-log-o: "\1f4c4";
$icon-file-zip-o: "\1f4e6";
$icon-file-archive-o: "\1f4e6";
$icon-file-audio: "\1f509";
$icon-file-sound: "\1f509";
$icon-file-excel-o: "\f1c3";
$icon-file-image-o: "\1f305";
$icon-file-photo-o: "\1f305";
$icon-file-picture-o: "\1f305";
$icon-mam: "\1f305";
$icon-file-infopath-o: "\e935";
$icon-file-onenote-o: "\e936";
$icon-file-pdf-o: "\f1c1";
$icon-file-powerpoint-o: "\f1c4";
$icon-file-video-o: "\1f4f9";
$icon-file-movie-o: "\1f4f9";
$icon-file-word-o: "\f1c2";
$icon-user: "\1f464";
$icon-customer-account: "\1f464";
$icon-users: "\1f465";
$icon-forum: "\1f465";
$icon-cog: "\2699";
$icon-wheel: "\2699";
$icon-settings: "\2699";
$icon-config: "\2699";
$icon-menu: "\2261";
$icon-bars: "\2261";
$icon-hamburger: "\2261";
$icon-filter: "\2228";
$icon-search: "\1f50d";
$icon-lupe: "\1f50d";
$icon-location: "\29bf";
$icon-gps: "\29bf";
$icon-close: "\274c";
$icon-cross: "\274c";
$icon-x: "\274c";
$icon-times: "\274c";
$icon-tick: "\2713";
$icon-check: "\2713";
$icon-angle-double-up: "\fe3d";
$icon-angle-double-right: "\300b";
$icon-angle-double-down: "\fe3e";
$icon-angle-double-left: "\300a";
$icon-angle-up: "\fe3f";
$icon-angle-right: "\3009";
$icon-angle-down: "\fe40";
$icon-angle-left: "\3008";
$icon-back-top: "\2191";
$icon-arrow-up: "\2191";
$icon-arrow-right: "\2192";
$icon-arrow-down: "\2193";
$icon-arrow-left: "\2190";
$icon-plus: "\2795";
$icon-minus: "\e918";
$icon-play: "\25b7";
$icon-playbutton: "\25b7";
$icon-youtube-play: "\25b7";
$icon-play-circle: "\e917";
$icon-play-circle-o: "\e917";
$icon-validation-check: "\1f503";
$icon-refresh: "\1f503";
$icon-save: "\1f4be";
$icon-floppy-o: "\1f4be";
$icon-print: "\1f5b6";
$icon-home: "\1f3e0";
$icon-house: "\1f3e0";
$icon-envelope-o: "\2709";
$icon-e-mail: "\2709";
$icon-mail: "\2709";
$icon-fax: "\1f4e0";
$icon-event: "\1f4c6";
$icon-calendar: "\1f4c6";
$icon-date: "\1f4c6";
$icon-list: "\2254";
$icon-caret-right: "\25b6";
$icon-triangle-right: "\25b6";
$icon-info-circle: "\2139";
$icon-lightbulb-o: "\1f4a1";
$icon-ideas-tool: "\1f4a1";
$icon-estate-ticket-tool: "\1f4c7";
$icon-book: "\1f4d5";
$icon-documentation: "\1f4d5";
$icon-it-documentation: "\1f4d5";
$icon-it-servicedesk: "\1f3ab";
$icon-money: "\1f4b6";
$icon-it-billing: "\1f4b6";
$icon-briefcase: "\1f4bc";
$icon-bag: "\1f4bc";
$icon-jobs: "\1f4bc";
$icon-identity-card: "\1f4b3";
$icon-customer-data: "\1f4b3";
$icon-sign-in: "\e901";
$icon-log-in: "\e901";
$icon-login: "\e901";
$icon-sign-out: "\e94b";
$icon-log-out: "\e94b";
$icon-logout: "\e94b";
$icon-media-asset-mail: "\1f4e8";
$icon-media-download: "\e94e";
$icon-media-generation: "\e94f";
$icon-mobile: "\1f4f1";
$icon-mobile-phone: "\1f4f1";
$icon-newspaper-o: "\1f4f0";
$icon-news: "\1f4f0";
$icon-user-search: "\1f50e";
$icon-people-search: "\1f50e";
$icon-call: "\260e";
$icon-phone: "\260e";
$icon-cashback: "\e916";
$icon-festool: "\e903";
